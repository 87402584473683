import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import emoji from "remark-emoji";
import {Box, Divider, Link, Typography} from "@mui/material";
import ReactMarkdown from "react-markdown";
import React, {useEffect, useMemo, useState} from "react";
// import * as Linking from 'expo-linking';


import './markdownRenderer.css'
import 'katex/dist/katex.min.css'
import {getStorage, ref, getBytes} from "firebase/storage";
import {mediaBufferManager} from "../../mediaBufferManager";


function CustomImage({alt, url}: { alt: string, url: string }) {


    const initialFileUrl = useMemo(() => {
        try {
            const urlObj = new URL(url)
            if (urlObj.protocol === "discuna-storage:") {
                const cloudStorageUrl = urlObj.href.slice(18)
                // const cloudStorageUrl = urlObj.pathname.slice(2)
                const buff = mediaBufferManager.getBuffer(cloudStorageUrl)
                if (buff) {
                    return URL.createObjectURL(new Blob([buff]))
                } else {
                    return ""
                }
            }
        } catch (e) {
            console.error("could not load image", e)
        }
        return url
    }, [url])

    const [fileUrl, setFileUrl] = useState(initialFileUrl)

    // download image if necessary
    useEffect(() => {
        try {
            const urlObj = new URL(url)
            if (urlObj.protocol === "discuna-storage:") {
                const storage = getStorage()
                // const cloudStorageUrl = urlObj.pathname.slice(2)
                const cloudStorageUrl = urlObj.href.slice(18)
                const storageRef = ref(storage, cloudStorageUrl)
                const buff = mediaBufferManager.getBuffer(cloudStorageUrl)
                if (!buff) {
                    console.log("downloading image")
                    getBytes(storageRef).then((buff) => {
                        const arr = new Uint8Array(buff)
                        mediaBufferManager.addBuffer(cloudStorageUrl, arr)
                        setFileUrl(URL.createObjectURL(new Blob([arr])))
                    })
                }
            }
        } catch (e) {
            console.error("could not load image", e)
        }
    }, [url])

    return <img alt={alt} src={fileUrl}/>
}

export const MarkdownRenderer = React.memo(({markdown, textAlign}: {
    markdown: string,
    textAlign?: "center"
}) => {
    return <ReactMarkdown remarkPlugins={[remarkMath, remarkGfm, emoji]}
                          rehypePlugins={[rehypeKatex]}
                          className={"qanda-markdown"}
                          components={{
                              h1: (props) => (<Typography variant={"h2"} sx={{
                                  textAlign,
                                  wordWrap: "break-word",
                                  mb: 0.5,
                                  width: "100%"
                              }}>
                                  {props.children}
                              </Typography>),
                              h2: (props) => (<Typography variant={"h3"} sx={{
                                  textAlign,
                                  wordWrap: "break-word",
                                  mb: 0.5,
                                  width: "100%"
                              }}>
                                  {props.children}
                              </Typography>),
                              h3: (props) => (<Typography variant={"h4"} sx={{
                                  textAlign,
                                  wordWrap: "break-word",
                                  mb: 0.25,
                                  width: "100%"
                              }}>
                                  {props.children}
                              </Typography>),
                              h4: (props) => (<Typography variant={"h5"} sx={{
                                  textAlign,
                                  wordWrap: "break-word",
                                  mb: 0,
                                  width: "100%"
                              }}>
                                  {props.children}
                              </Typography>),
                              h5: (props) => (<Typography variant={"h6"} sx={{
                                  textAlign,
                                  wordWrap: "break-word",
                                  mb: 0,
                                  width: "100%"
                              }}>
                                  {props.children}
                              </Typography>),
                              h6: (props) => (<Typography variant={"h6"} sx={{
                                  textAlign,
                                  wordWrap: "break-word",
                                  mb: 0,
                                  width: "100%"
                              }}>
                                  {props.children}
                              </Typography>),
                              p: (props) => (
                                  <Typography variant={"body1"} sx={{wordWrap: "break-word", textAlign, width: "100%"}}>
                                      {props.children}
                                  </Typography>),
                              hr: (props) => (<Divider style={{alignSelf: "stretch", margin: "12px 0px"}}/>),
                              table: (props) => (<div className={"qanda-table"}>
                                  {props.children}
                              </div>),
                              img: (props) => {
                                  return <CustomImage alt={props.alt ?? "no description available"}
                                                      url={props.src ?? ""}/>
                              },
                              a: (props) => {

                                  let url = props.href ?? ""
                                  if (!url.includes("www.") && !url.includes("http") && !url.startsWith("mailto")) {
                                      url = "www." + url
                                  }
                                  try {
                                      new URL(url)
                                  } catch {
                                      url = "http://" + url
                                  }
                                  return (
                                      <>
                                          <a href={url} target={"_blank"} rel="noreferrer">{"" + props.children}</a>
                                      </>
                                  )
                              }
                          }}>
        {markdown}
    </ReactMarkdown>
})
